.mainLogoHeader{
  max-height: 40px;
}
/* .searchForm{
  display: flex;
  align-items: flex-end;
  column-gap: 20px;
} */
/* .flex1{
  flex:1
} */
body .customTablee td{
  white-space: pre-wrap !important;
    text-align: left;
    font-size: 14px;
    color: #000 !important;

}
.customTablee {
  display: flex;
}
.customTablee thead tr{
  display: flex;
  flex-direction: column;
}
.customTablee thead tr th{
 min-height: 60px;
 --bs-table-accent-bg: #f3f3f3 !important;
}
.customTablee tbody{
  overflow: auto;
  display: flex;
  flex: 1;
}
.customTablee tbody tr{
  display: flex;
  flex-direction: column;
  min-width: max-content;
  flex:1
}
.customTablee tbody tr td{
  min-height: 60px;
  padding: 0.75rem 1.5rem;
  border-bottom: 1px solid #e9ecef !important;
  --bs-table-accent-bg: #fff !important;
 }